import './App.scss';
import About from './pages/AboutUs/About';
import BeforeLeave from './pages/BeforeLeave/BeforeLeave';
import Contact from './pages/Contact/Contact';
import Footer from './pages/Footer/Footer';
import HeaderBottom from './pages/Header/Header-Bottom/Header-Bottom';
import HeaderMiddle from './pages/Header/Header-Middle/Header-Middle';
import HeroSection from './pages/HeroSection/HeroSection';
import HotOffers from './pages/HotOffers/HotOffers';
import HowSolve from './pages/HowSolve/HowSolve';
import NotSure from './pages/NotSure/NotSure';
import Services from './pages/Services/Services';
import WhyClient from './pages/WhyClient/WhyClient';

function App() {
  return (
    <div className="app">
      <HeaderBottom/>
      <section className='stack1 stack'>
      <HeaderMiddle/>
        <HeroSection/>
      </section>
      <section className='stack2 stack'>
        <HotOffers/>
        <NotSure/>
      </section>
      <section className='stack3 stack'>
        <Services/>
      </section>
      <section className='stack3 stack'>
        <HowSolve/>
      </section>
      <section className='stack4 stack'>
        <WhyClient/>
        <BeforeLeave/>
      </section>
      <section className='stack5 stack'>
        <About/>
      </section>
      <section className='stack6 stack'>
        <Contact/>
      </section>
      <Footer/>
    </div>
    
  );
}

export default App;
