import React from 'react';
import './BeforeLeave.scss'
import { useInView } from 'react-intersection-observer';
import {ReactComponent as SVG} from '../../assets/beforeLeave.svg'
import Button from '../../components/Button/Button';
const BeforeLeave = () => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: .6,
  });
  if(inView){
    const targets=entry.target.querySelectorAll('.beforeLeave--animation');
    targets.forEach((target)=>{
      target.style.animation=`beforeLeaveAnim ${target.dataset.delay} forwards ease`;
    })
  }
  else{
    if(entry!=null){
      const targets=entry.target.querySelectorAll('.beforeLeave--animation');
      targets.forEach((target)=>{
        target.style.animation="none";
      })
    }
  }
  return <>
      <div ref={ref} className="beforeLeave">
        <div className="beforeLeave__svg">
          <SVG/>
        </div>
        <div className="beforeLeave__heading heading-1">
          <span className='beforeLeave__heading-head beforeLeave--animation' data-delay=".8s">One-stop repair shop<span>.</span></span>
          <span className='beforeLeave__heading-head beforeLeave--animation' data-delay="1.2s">For your computer<span>.</span></span>
        </div>
        <div className="beforeLeave__subheading subheading">
          <span className="beforeLeave--animation" data-delay="1.2s">Need a computer repair? </span>
          <span className="beforeLeave--animation" data-delay="1.2s">Call us <span>+91 (98) 29 555 351</span></span>
        </div>
        <div className="beforeLeave__button beforeLeave--animation" data-delay="2s">
          <Button>Request a quote</Button>
        </div>
      </div>
  </>;
};

export default BeforeLeave;
