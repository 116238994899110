import React from 'react';
import { useInView } from 'react-intersection-observer';
import './HotOffers.scss';
const HotOffers = () => {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: .6,
      });
      if(inView){
        const targets=entry.target.querySelectorAll('.hotOffers__images--animation');
        targets.forEach((target)=>{
          if(target.dataset.type==='odd'){
            target.style.animation=`hotOffersOddAnim 1s forwards ease`;
          }
          else{
            target.style.animation=`hotOffersEvenAnim 1s forwards ease`;
          }
        })
      }
      else{
        if(entry!=null){
          const targets=entry.target.querySelectorAll('.hotOffers__images--animation');
          targets.forEach((target)=>{
            target.style.animation="none";
          })
        }
      }
    
  return <>
      <div ref={ref} id="offers" className="hotOffers">
        <div className="hotOffers__heading heading-1">
            Ours Hot Offers
        </div>
        <div className="hotOffers__images">
            <div className="hotOffers__images-img hotOffers__images--animation" data-type="odd">
                <img src="./fast repair.webp" alt="fast repair" />
                <div className="hotOffers__images-img--subheading subheading">Fast Repair</div>
                <div className="hotOffers__images-img--description">Cleaning included</div>
            </div>
            <div className="hotOffers__images-img hotOffers__images--animation" data-type="even">
                <img src="./spyware.webp" alt="spyware" />
                <div className="hotOffers__images-img--subheading subheading">Spyware Removal</div>
                <div className="hotOffers__images-img--description">Free for the Second device</div>
            </div>
            <div className="hotOffers__images-img hotOffers__images--animation" data-type="odd">
                <img src="./harddrive.webp" alt="harddrive" />
                <div className="hotOffers__images-img--subheading subheading">Data Recovery</div>
                <div className="hotOffers__images-img--description">Within 12 hours</div>
            </div>
            <div className="hotOffers__images-img hotOffers__images--animation" data-type="even">
                <img src="./pcbuild.webp" alt="pcbuild" />
                <div className="hotOffers__images-img--subheading subheading">PC Build</div>
                <div className="hotOffers__images-img--description">Best manufacturers</div>
            </div>
            
        </div>
      </div>
  </>;
};

export default HotOffers;
