import React from 'react';
import './WhyClient.scss'

const WhyClientIndex = () => {
  return <>
      <div id="choose" className="whyClient">
        <div className="whyClient__heading heading-1">Why clients choose us</div>
        <div className="whyClient__boxes">
          <div className="whyClient__boxes-box">
            <div className="whyClient__boxes-box--img">
              <img src='./time.webp' alt="time"/>
            </div>
            <div className="whyClient__boxes-box--content">
              <div className="whyClient__boxes-box--content-heading heading-4">Time Work</div>
            </div>
          </div>
          {/* //////////// */}
          <div className="whyClient__boxes-box">
            <div className="whyClient__boxes-box--img">
              <img src='./expert.webp' alt="expert"/>
            </div>
            <div className="whyClient__boxes-box--content">
              <div className="whyClient__boxes-box--content-heading heading-4">Expert Team</div>
            </div>
          </div>
          {/* ///////////// */}
          <div className="whyClient__boxes-box">
            <div className="whyClient__boxes-box--img">
              <img src='./handshake.webp' alt="handshake"/>
            </div>
            <div className="whyClient__boxes-box--content">
              <div className="whyClient__boxes-box--content-heading heading-4">Genuine Work</div>
            </div>
          </div>
        </div>
      </div>
  </>;
};

export default WhyClientIndex;
