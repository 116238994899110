import React from 'react';
import './Header-Middle.scss'
import {ReactComponent as Email} from '../../../assets/headerMail.svg';
import {ReactComponent as Clock} from '../../../assets/clock.svg';
import {ReactComponent as Phone} from '../../../assets/mobile.svg';
import {ReactComponent as Logo} from '../../../assets/logo.svg';
const HeaderMiddle = () => {
  return <>
      <div className='headerMiddle'>
        <div className="headerMiddle__logo"><Logo/></div>
        <div className="headerMiddle__info">
          <div className="headerMiddle__info-content">
            <Email/>
            <div className="headerMiddle__info-content--text">
              <span className='subheading-light'>Email</span>
              <span>helpdesk@alokcomputers.com</span>
            </div>
          </div>
          {/* //////////////// */}
          <div className="headerMiddle__info-content">
            <Phone/>
            <div className="headerMiddle__info-content--text">
              <span className='subheading-light'>Phone</span>
              <span>+919829555351</span>
            </div>
          </div>
          {/* ///////////////// */}
          <div className="headerMiddle__info-content">
            <Clock/>
            <div className="headerMiddle__info-content--text">
              <span className='subheading-light'>Opening Time</span>
              <span>Mon-Sat: 10Am - 6Pm</span>
            </div>
          </div>
        </div>
      </div>
  </>;
};

export default HeaderMiddle;
