import React from 'react';
import { useInView } from 'react-intersection-observer';
import './HowSolve.scss';
const HowSolve = () => {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: .6,
      });
      if(inView){
        const targets=entry.target.querySelectorAll('.howSolve__cards-card');
        targets.forEach((target)=>{
          if(target.dataset.type==='left'){
            target.style.animation=`howSolveLeftAnim 1s forwards ease`;
          }
          else{
            target.style.animation=`howSolveRightAnim 1s forwards ease`;
          }
        })
      }
      else{
        if(entry!=null){
          const targets=entry.target.querySelectorAll('.howSolve__cards-card');
          targets.forEach((target)=>{
            target.style.animation="none";
          })
        }
      }
  return <>
      <div ref={ref} id="issues" className="howSolve">
          <div className="howSolve__heading heading-1">
            How we solve issue
          </div>
          <div className="howSolve__cards">
              <div className="howSolve__cards-card1 howSolve__cards-card" data-type="left">
                  <span className='tertiary-heading'>Request an Appointment</span>
              </div>
              <div className="howSolve__cards-card2 howSolve__cards-card" data-type="right">
                  <span className='tertiary-heading'>In-office/home repair</span>
              </div>
          </div>
          <div className="howSolve__buy">
            <div className="howSolve__buy-heading heading-2">Get you new PC now!</div>
            <div className="howSolve__buy-subheading subheading">
              <span>Personalized PCs based on your </span>
              <span>needs and budget</span>
            </div>
          </div>
      </div>
  </>;
};

export default HowSolve;