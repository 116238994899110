import React from 'react';
import './Header-Bottom.scss';
const HeaderBottom = () => {
  return <>
      <div className='headerBottom'>
        <div className="headerBottom__pages">
          <a href="#home" className="headerBottom__pages-page paragraph">Home</a>
          <a href="#offers" className="headerBottom__pages-page paragraph">Offers</a>
          <a href="#services" className="headerBottom__pages-page paragraph">services</a>
          <a href="#issues" className="headerBottom__pages-page paragraph">issues</a>
          <a href="#choose" className="headerBottom__pages-page paragraph">Competitors</a>
          <a href="#about" className="headerBottom__pages-page paragraph">About</a>
          <a href="#contact" className="headerBottom__pages-page paragraph">Contact</a>
          {/* <div className="headerBottom__pages-page paragraph">Contact</div> */}
        </div>
      </div>
  </>;
};

export default HeaderBottom;
