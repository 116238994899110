import React from 'react';
import './Footer.scss';
const Footer = () => {
  return <>
      <div className="footer">
        <div className="footer__copyright subheading-light">&copy; Alok Computers 2000</div>
      </div>
  </>;
};

export default Footer;
