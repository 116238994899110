import React,{useRef,useState} from 'react';
import {ReactComponent as SVG} from '../../assets/online-world-animate.svg'
import {ReactComponent as Email} from '../../assets/email.svg'
import {ReactComponent as Location} from '../../assets/location.svg'
import {ReactComponent as Phone} from '../../assets/phone.svg'
import {ReactComponent as Whatsapp} from '../../assets/Whatsapp.svg'
import { useInView } from 'react-intersection-observer';
import emailjs from '@emailjs/browser';
import './Contact.scss';

const Contact = () => {
  const [forminput,setforminput]=useState({
    user_name:'',
    user_email:'',
    user_phoneNumber:'',
    user_description:''
})

  const form = useRef();
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: .6,
      });

      const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_USER_ID)
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          setforminput({
            user_name:'',
            user_email:'',
            user_phoneNumber:'',
            user_description:''
        })
      };

      if(inView){
        const targets=entry.target.querySelectorAll('.contact--animation');
        targets.forEach((target)=>{
          target.style.animation=`contactAnim ${target.dataset.delay} forwards ease`;
        })
      }
      else{
        if(entry!=null){
          const targets=entry.target.querySelectorAll('.contact--animation');
          targets.forEach((target)=>{
            target.style.animation="none";
          })
        }
      }

      const onChange=(e)=>{
        const {name,value}=e.target;
        setforminput(()=>{
        return {
            ...forminput,
            [name]:value
        }
    })
}
  return <>
      <div ref={ref} className="contact" id="contact">
          <div className="contact__description">
            <div className="contact__description-subheading subheading contact--animation" data-delay=".6s">Contact us</div>
            <div className="contact__description-heading heading-1 contact--animation" data-delay=".8s"><span>Let's Get in</span><span>Touch.</span></div>
            <div className="contact__description-paragraph paragraph contact--animation" data-delay=".1s"><span>Feel free to reach out for collaborations or </span><span>just a friendly hello!</span></div>
          </div>
        
        <div className="contact__social">
            <div className="contact__social-item contact--animation" data-delay="1.2s">
                <Email/>
                <span>helpdesk@alokcomputers.com</span>
            </div>
            <div className="contact__social-item contact--animation" data-delay="1.2s">
                <Location/>
                <span className='contact__social-item--text'>
                    <span>S-33, Alankar Plaza, Central Spine, </span>
                    <span>Vidhyadhar Nagar, </span>
                    <span>Jaipur-302039, Rajasthan</span>
                </span>
            </div>
            <div className="contact__social-item contact--animation" data-delay="1.2s">
                <Phone/>
                <span>+91 9829555351</span>
            </div>
            <div className="contact__social-item contact--animation" data-delay="1.2s">
                <Whatsapp/>
                <span><a href="https://wa.me/+919829555351" target="_blank" rel="noreferrer">Contact using whatsapp</a></span>
            </div>
        </div>
        <div className="contact__svg contact--animation" data-delay="1.5s">
            <SVG/>
        </div>
        <form className="contact__form contact--animation" data-delay="2s" ref={form} onSubmit={sendEmail}>
            <div className="contact__form--inputContainer">
                <input className="contact__form--inputContainer-input" name="user_name" placeholder='Name' value={forminput.user_name} onChange={onChange} required/>
            </div>
            <div className="contact__form--inputContainer">
                <input className="contact__form--inputContainer-input" type='email' name="user_email" placeholder='Email' value={forminput.user_email} onChange={onChange} required/>
            </div>
            <div className="contact__form--inputContainer">
                <input className="contact__form--inputContainer-input" type='number' name="user_phoneNumber" placeholder='Phone Number' value={forminput.user_phoneNumber} onChange={onChange}/>
            </div>
            <div className="contact__form--inputContainer">
                <textarea className="contact__form--inputContainer-input" id="" cols="30" rows="5" name="user_description" placeholder='Description' value={forminput.user_description} onChange={onChange} required></textarea>
            </div>
            <div className="contact__form--button">
                <button type='submit'>Let's Talk!</button>
            </div>
        </form>
      </div>
  </>;
};

export default Contact;
