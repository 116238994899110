import React from 'react';
import Button from '../../components/Button/Button';
import { InView } from 'react-intersection-observer';
import './HeroSection.scss'

const HeroSection = () => {  

  return <>
      <InView as="div" className='heroSection' id="home" onChange={(inView, entry) => {
          if(inView){
            const targets=entry.target.querySelectorAll('.heroSection__animation');
            targets.forEach((target)=>{
              target.style.animation=`heroSectionAnim ${target.dataset.delay} forwards ease`;
            })
          }
          else{
            if(entry!=null){
              const targets=entry.target.querySelectorAll('.heroSection__animation');
              targets.forEach((target)=>{
                target.style.animation="none";
              })
            }
          }
      }} threshold=".6">
        <div className="heroSection__left">
          <div className="heroSection__left-heading heading-1">
            <span className='heroSection__animation' data-delay=".5s">88% of Laptops Are</span>
            <span className='heroSection__animation' data-delay="1s">Repaired Within</span>
            <span className='heroSection__animation' data-delay="1.25s">12-24 Hours</span>
            </div>
            <div className="heroSection__left-subheading heading-4 heroSection__animation" data-delay="1.25s">
            Many different types of devices can be repaired by our technicians, including computers, smartphones, tablets, gaming consoles, etc. For us, the repair work is not just about finding the fault and fixing it, it is about quality parts that are made to last long. We make sure that each of the parts used for repairing is brand new and of the highest quality
            </div>
            {/* <div className="heroSection__left-subheading heading-4 heroSection__animation" data-delay="1.25s">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </div> */}
            <div className="heroSection__left-contact paragraph heroSection__animation" data-delay="2.5s">
              <Button>Contact us</Button>
            </div>
        </div>
        <div className="heroSection__right">
          <div className="heroSection__right-img"></div>
        </div>
      </InView>
  </>;
};

export default HeroSection;
