import React from 'react';
import './Button.scss';
const Button = ({children}) => {
  return <>
      <a href="#contact" className='button paragraph'>
        {children}
      </a>
  </>;
};

export default Button;
