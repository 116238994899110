import React from 'react';
import { useInView } from 'react-intersection-observer';
import {ReactComponent as Logo} from '../../assets/logo.svg';
import './About.scss';
const About = () => {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: .25,
      });
      if(inView){
        const targets=entry.target.querySelectorAll('.about--animation');
        targets.forEach((target)=>{
          target.style.animation=`aboutAnim ${target.dataset.delay} forwards ease`;
        })
      }
      else{
        if(entry!=null){
          const targets=entry.target.querySelectorAll('.about--animation');
          targets.forEach((target)=>{
            target.style.animation="none";
          })
        }
      }
  return <>
      <div ref={ref} className="about" id="about">
        <div className="about__logo about--animation" data-delay=".8s"><Logo/></div>
        <div className="about__quote quote_heading about--animation" data-delay="1.2s">Alok Computers was founded in Jaipur in 2000. The mission: to help businesses harness the power of technology.</div>
        <div className="about__founder subheading-light about--animation" data-delay="1.5s"> —&nbsp;&nbsp;Alok Agarwal</div>
        <div className="about__content">
            <div className="about__content-text about--animation" data-delay="1.6s">
                <div className="about__content-text--heading heading-2">Who we are</div>
                <div className="about__content-text--paragraph subheading-light">Alok Computers is a PC solution company based in Jaipur. We focus solely on quality - not price. We discovered it was infinitely more rewarding to do excellent work and charge a fair price for it. Rather than cut corners and try to be the cheapest</div>
            </div>
            <div className="about__content-text about--animation" data-delay="1.6s">
                <div className="about__content-text--heading heading-2">What we do</div>
                <div className="about__content-text--paragraph subheading-light">Since 2000, Alok Computers has helped companies and consumers navigate the world of technology. We specialize in computer repair, data recovery, network support and security.</div>
            </div>
            <div className="about__content-text about--animation" data-delay="1.6s">
                <div className="about__content-text--heading heading-2">Why we do it</div>
                <div className="about__content-text--paragraph subheading-light">Technology is cool. But it isn’t easy to use.  We love helping our clients understand the options available to them. come hell or high water, we want to give our clients the right solution, even if it means introducing them to a competitor.</div>
            </div>
        </div>
      </div>
  </>;
};

export default About;
