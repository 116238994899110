import React from 'react';
import Button from '../../components/Button/Button';
import './NotSure.scss';
const NotSure = () => {
  return <>
      <div className="notSure">
        <div className="notSure__heading heading-2">Not Sure What You Need?</div>
        <div className="notSure__text heading-4">
            Whatever you need fixed, one of our repair technicians will happily advise and assist you. Our team members are available to answer all of your questions and concerns. 
        </div>
        <div className="notSure__button">
            <Button>Order a Callback</Button>
        </div>
      </div>
  </>;
};

export default NotSure;
