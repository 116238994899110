import React from 'react';
import './Services.scss';
import {ReactComponent as Speaker} from '../../assets/speaker.svg';
import {ReactComponent as Laptop} from '../../assets/Laptop.svg';
import {ReactComponent as Router} from '../../assets/router.svg';
import {ReactComponent as Printer} from '../../assets/printer.svg';
import {ReactComponent as Headphone} from '../../assets/headphone.svg';
import {ReactComponent as Pendrive} from '../../assets/pendrive.svg';
import {ReactComponent as SSD} from '../../assets/ssd.svg';
import {ReactComponent as Keyboard} from '../../assets/keyboard.svg';
import {ReactComponent as Desktop} from '../../assets/desktop.svg';
import { useInView } from 'react-intersection-observer';
const Services = () => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: .6,
  });
  if(inView){
    const targets=entry.target.querySelectorAll('.service__content--animation');
    targets.forEach((target)=>{
      target.style.animation=`serviceAnim ${target.dataset.delay} forwards ease`;
    })
  }
  else{
    if(entry!=null){
      const targets=entry.target.querySelectorAll('.service__content--animation');
      targets.forEach((target)=>{
        target.style.animation="none";
      })
    }
  }

  return <>
      <div ref={ref} id="services" className="services">
        <div className="services__heading heading-1">Services</div>
        <div className="services__content">
            <div className="service__content-block service__content--animation" data-delay='.6s'><Desktop/></div>
            <div className="service__content-block service__content--animation" data-delay='.8s'><Keyboard/></div>
            <div className="service__content-block service__content--animation" data-delay='1s'><Speaker/></div>
            <div className="service__content-block service__content--animation" data-delay='1.2s'><Router/></div>
            <div className="service__content-block service__content--animation" data-delay='1.4s'><Headphone/></div>
            <div className="service__content-block service__content--animation" data-delay='1.6s'><Laptop/></div>
            <div className="service__content-block service__content--animation" data-delay='1.8s'><Printer/></div>
            <div className="service__content-block service__content--animation" data-delay='2s'><Pendrive/></div>
            <div className="service__content-block service__content--animation" data-delay='2.2s'><SSD/></div>
        </div>
      </div>
  </>;
};

export default Services;
